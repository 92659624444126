import React from 'react'
import PropTypes from 'prop-types'
import { withRuntimeContext } from 'vtex.render-runtime'
import { IconCaretDown } from 'vtex.styleguide'
import { Value } from 'react-values'

const DEFAULT_LOCALE = 'en-US'

const locales = {
  'en-US': 'English',
  'pt-BR': 'Português',
  'es-AR': 'Español',
  'bg-BG': 'български',
  'de-DE': 'Deutsch',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'ko-KO': '한국어',
  'nl-NL': 'Nederlands',
  'ro-RO': 'Română',
  'th-TH': 'ไทย',
}

const LocaleSwitcher = ({ runtime }) => {
  const { emitter, culture } = runtime
  const defaultValue = locales[culture.locale] ? culture.locale : DEFAULT_LOCALE

  return (
    <Value defaultValue={defaultValue}>
      {({ value, set }) => {
        const handleSelectLanguage = event => {
          set(event.target.value)
          emitter.emit('localesChanged', event.target.value)
        }
        return (
          <div className="flex flex-inline relative w4 w-auto-ns">
            {locales[value]}{' '}
            <div className="ml2">
              <IconCaretDown size={8} color="currentColor" />
            </div>
            <select
              className="absolute o-0"
              onChange={handleSelectLanguage}
              defaultValue={defaultValue}>
              {Object.entries(locales).map(([locale, lang]) => (
                <option key={locale} value={locale}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
        )
      }}
    </Value>
  )
}

LocaleSwitcher.propTypes = {
  runtime: PropTypes.any,
}

export default withRuntimeContext(LocaleSwitcher)
